<template>
  <v-card
    class="pa-1"
    elevation="0">
    <div class="product-card pa-1">
      <img
        style="object-fit: fill;"
        :style="{
          width: imgWidth,
          height: imgHeight
        }"
        :src="imageUrl ? `${imageUrl}?fm=webp&w=100&h=150` : null" />
      <p class="text-center mb-0 mt-auto">
        <span style="font-size: 14px;">
          {{ productModel }}
        </span>
        <br>
        <v-chip
          v-if="!product.skus"
          filter
          x-small
          :outlined="false"
          label>
          DRAFT
        </v-chip>
        <v-chip
          v-else-if="checkOutOfStock"
          filter
          x-small
          :outlined="false"
          color="pink"
          label
          dark>
          ของหมด
        </v-chip>
        <v-chip
          v-else-if="checkOutOfStockWeb"
          filter
          x-small
          :outlined="false"
          color="orange"
          label
          dark>
          ของบนเว็บหมด
        </v-chip>
        <v-chip
          v-else
          filter
          x-small
          :outlined="false"
          color="success"
          label>
          ปกติ
        </v-chip>
      </p>
    </div>
  </v-card>
</template>

<script>
import NoImgAvailable from '@/assets/image/no_picture_available.png'
import { mapGetters } from 'vuex'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    imgWidth: {
      type: String,
      default: '100px'
    },
    imgHeight: {
      type: String,
      default: '150px'
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse',
      warehouses: 'Store/warehouse'
    }),
    imageUrl () {
      return this.product.photoUrls?.length > 0 ? this.product.photoUrls[0] : NoImgAvailable
    },
    productModel () {
      return this.product.model
    },
    checkOutOfStock () {
      let isOutOfStock = true

      for (const sku of this.product.skus || []) {
        const stock = (sku.stock.all.onHandQty - sku.stock.all.onReservedQty)
        if (stock > 0) {
          isOutOfStock = false
          break
        }
      }

      return isOutOfStock
    },
    checkOutOfStockWeb () {
      let isOutOfStock = true

      for (const sku of this.product.skus || []) {
        const stock = (sku.stock.fulfillment.onHandQty - sku.stock.fulfillment.onReservedQty)
        if (stock > 0) {
          isOutOfStock = false
          break
        }
      }

      return isOutOfStock
    }
  }

}
</script>

<style lang="scss" scoped>
.product-card {
  border: thin dashed #afafaf;
  max-width: 100px;
}
</style>

import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class SortingDraftProvider extends HttpRequest {
  getSortingDraft (query) {
    this.setHeader(getAuthToken())
    return this.get('sorting-draft', query)
  }

  getSortingDraftById (id) {
    this.setHeader(getAuthToken())
    return this.get(`sorting-draft/${id}`)
  }

  createSortingDraft (sortingDraftForm) {
    this.setHeader(getAuthToken())
    return this.post('sorting-draft', sortingDraftForm)
  }

  updateSortingDraft (sortingDraftForm) {
    this.setHeader(getAuthToken())
    return this.patch(`sorting-draft/${sortingDraftForm.id}`, sortingDraftForm)
  }

  deleteSortingDraft (id) {
    this.setHeader(getAuthToken())
    return this.delete(`sorting-draft/${id}`)
  }
}

export default SortingDraftProvider

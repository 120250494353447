<template>
  <v-dialog
    :value="true"
    persistent
    max-width="300px">
    <v-card class="rounded elevation-3 px-5 py-5">
      <v-form
        ref="form"
        v-model="validate">
        <h2 class="mb-1">
          Sorting Draft
        </h2>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="formData.name"
              :rules="textFieldRules"
              label="Draft name"
              color="secondary"
              outlined
              dense />
          </v-col>
          <v-col
            cols="12"
            class="mb-2">
            <v-btn
              color="secondary"
              block
              @click="onClose()">
              Back
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="success"
              block
              @click="onSubmit()">
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      validate: false,
      textFieldRules: [
        (v) => !!v || 'Field is require!'
      ]
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.$emit('on-submit')
      }
    },
    onClose () {
      this.$emit('on-close')
    }
  }
}
</script>
